.rpv-core-annotation,
.rpv-core-arrow {
  position: absolute;
}
.rpv-core-arrow {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  border-left-color: rgba(0, 0, 0, 0.3);
  border-right: 1px solid rgba(0, 0, 0, 0.3);
  border-top-color: rgba(0, 0, 0, 0.3);
  height: 10px;
  width: 10px;
  z-index: 0;
}
.rpv-core-arrow-tl {
  bottom: 0;
  left: 0;
  transform: translate(50%, 50%) rotate(45deg);
}
.rpv-core-arrow-tc {
  left: 50%;
}
.rpv-core-arrow-tc,
.rpv-core-arrow-tr {
  bottom: 0;
  transform: translate(-50%, 50%) rotate(45deg);
}
.rpv-core-arrow-tr {
  right: 0;
}
.rpv-core-arrow-rt {
  left: 0;
  top: 0;
  transform: translate(-50%, 50%) rotate(135deg);
}
.rpv-core-arrow-rc {
  top: 50%;
}
.rpv-core-arrow-rb,
.rpv-core-arrow-rc {
  left: 0;
  transform: translate(-50%, -50%) rotate(135deg);
}
.rpv-core-arrow-rb {
  bottom: 0;
}
.rpv-core-arrow-bl {
  left: 0;
  top: 0;
  transform: translate(50%, -50%) rotate(225deg);
}
.rpv-core-arrow-bc {
  left: 50%;
}
.rpv-core-arrow-bc,
.rpv-core-arrow-br {
  top: 0;
  transform: translate(-50%, -50%) rotate(225deg);
}
.rpv-core-arrow-br {
  right: 0;
}
.rpv-core-arrow-lt {
  right: 0;
  top: 0;
  transform: translate(50%, 50%) rotate(315deg);
}
.rpv-core-arrow-lc {
  top: 50%;
}
.rpv-core-arrow-lb,
.rpv-core-arrow-lc {
  right: 0;
  transform: translate(50%, -50%) rotate(315deg);
}
.rpv-core-arrow-lb {
  bottom: 0;
}
.rpv-core-asking-password {
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}
.rpv-core-asking-password-message {
  margin: 8px 0;
}
.rpv-core-asking-password-input-container {
  align-items: center;
  display: flex;
  justify-content: center;
}
.rpv-core-asking-password-input {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 8px;
  width: 200px;
}
.rpv-core-asking-password-button {
  background-color: #357edd;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-left-color: transparent;
  color: #fff;
  cursor: pointer;
  padding: 8px 16px;
}
.rpv-core-button {
  background-color: transparent;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 8px;
}
.rpv-core-button-selected,
.rpv-core-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.rpv-core-canvas-layer {
  left: 0;
  position: absolute;
  overflow: hidden;
  top: 0;
}
.rpv-core-canvas-layer-loader {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: #fff;
  z-index: 1;
}
.rpv-core-canvas-layer-loader,
.rpv-core-doc-error {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
}
.rpv-core-doc-error-text {
  background-color: #e53e3e;
  border-radius: 0.25rem;
  color: #fff;
  line-height: 1.5;
  max-width: 50%;
  padding: 0.5rem;
}
.rpv-core-doc-loading {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}
.rpv-core-icon {
  fill: none;
  stroke: #000;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1;
  text-align: center;
}
.rpv-core-inner-pages-vertical {
  display: flex;
  flex-direction: column;
}
.rpv-core-inner-pages-horizontal,
.rpv-core-inner-pages-wrapped {
  display: flex;
  flex-direction: row;
}
.rpv-core-inner-pages-wrapped {
  flex-wrap: wrap;
  justify-content: center;
}
.rpv-core-inner-page {
  background: #fff;
  padding: 8px;
}
.rpv-core-annotation-link a {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.rpv-core-annotation-link a:hover {
  background: rgba(255, 255, 0, 0.2);
}
.rpv-core-menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.rpv-core-menu-divider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  margin: 4px 0;
}
.rpv-core-menu-item {
  align-items: center;
  display: flex;
  padding: 4px 0;
}
.rpv-core-menu-item:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.rpv-core-menu-item-icon {
  padding-left: 16px;
  padding-right: 8px;
}
.rpv-core-menu-item-label {
  flex-grow: 1;
  flex-shrink: 1;
  padding-right: 32px;
  white-space: nowrap;
}
.rpv-core-menu-item-check {
  padding-right: 16px;
}
.rpv-core-modal-body {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  margin: 160px auto 0;
  max-width: 480px;
}
.rpv-core-modal-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999;
}
.rpv-core-page-layer {
  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.2);
  margin: 0 auto;
  position: relative;
}
.rpv-core-page-layer,
.rpv-core-page-size-calculator {
  align-items: center;
  display: flex;
  justify-content: center;
}
.rpv-core-page-size-calculator {
  height: 100%;
  width: 100%;
}
.rpv-core-popover-body {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  left: 0;
  padding: 8px 0;
  position: absolute;
  top: -9999px;
  z-index: 9999;
}
.rpv-core-popover-body-arrow {
  background: #fff;
}
.rpv-core-popover-overlay {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}
.rpv-core-annotation-popup-wrapper {
  background-color: #faf089;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  font-size: 0.75rem;
  margin-left: 0.25rem;
  position: absolute;
  top: 0;
  width: 16rem;
  word-wrap: break-word;
}
.rpv-core-annotation-popup-wrapper-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0.25rem;
}
.rpv-core-annotation-popup-wrapper-title {
  font-weight: 600;
  margin-right: 1rem;
}
.rpv-core-annotation-popup-wrapper-content {
  border-top: 1px solid #1a202c;
  padding: 0.25rem;
  max-height: 16rem;
  overflow: auto;
}
.rpv-core-primary-button {
  background-color: #357edd;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  padding: 8px;
}
.rpv-core-progress-bar {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 9999px;
}
.rpv-core-progress-bar-inner {
  align-items: center;
  background-color: #357edd;
  border-radius: 9999px;
  color: #fff;
  display: flex;
  font-size: 10px;
  justify-content: center;
}
.rpv-core-separator {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
.rpv-core-spinner {
  animation-duration: 0.75s;
  animation-name: rpv-core-spinner-transform;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  transition-property: transform;
}
.rpv-core-spinner-circle {
  fill: none;
  stroke: rgba(0, 0, 0, 0.4);
  stroke-linecap: round;
  stroke-width: 2;
}
@keyframes rpv-core-spinner-transform {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}
.rpv-core-text-layer {
  height: 100%;
  left: 0;
  line-height: 1;
  position: absolute;
  top: 0;
  width: 100%;
}
.rpv-core-text {
  color: transparent;
  cursor: text;
  opacity: 0.2;
  position: absolute;
  transform-origin: 0 0;
  white-space: pre;
}
.rpv-core-text::selection {
  background: #00f;
  color: transparent;
}
.rpv-core-text-highlight {
  background-color: #b400aa;
  border-radius: 4px;
  margin: -1px;
  padding: 1px;
}
.rpv-core-tooltip-body {
  background: #000;
  border-radius: 4px;
  color: #fff;
  left: 0;
  max-width: 300px;
  position: absolute;
  text-align: center;
  top: -9999px;
  z-index: 9999;
}
.rpv-core-tooltip-body-arrow {
  background-color: #000;
}
.rpv-core-tooltip-body-content {
  padding: 8px;
}
.rpv-drop-area {
  align-items: center;
  background-color: #fff;
  border: 2px dashed rgba(0, 0, 0, 0.3);
  bottom: 0;
  display: flex;
  font-size: 24px;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9999;
}
.rpv-full-screen-exit-button {
  bottom: 0;
  padding: 8px;
  position: fixed;
  right: 0;
}
.rpv-full-screen-exit-button-inner {
  background: #fff;
}
.rpv-open-button {
  background-color: transparent;
  border: none;
  border-radius: 4px;
  padding: 8px;
  position: relative;
}
.rpv-open-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.rpv-open-button-input {
  bottom: 0;
  cursor: pointer;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
.rpv-open-button-input::-webkit-file-upload-button {
  width: 0;
}
.rpv-current-page-input {
  border: 1px solid rgba(0, 0, 0, 0.3);
  padding: 4px;
  text-align: right;
  width: 50px;
}
.rpv-print-progress {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9999;
}
.rpv-print-progress-inner {
  background-color: #fff;
  border-radius: 4px;
  padding: 24px;
  text-align: center;
  width: 240px;
}
.rpv-print-progress-bar {
  margin-bottom: 16px;
}
.rpv-print-progress-message {
  margin-bottom: 8px;
}
.rpv-print-zone {
  display: none;
}
@media print {
  @page {
    margin: 0;
  }
  .rpv-html-printing {
    height: 100%;
  }
  .rpv-body-printing {
    height: 100%;
    margin: 0;
  }
  .rpv-body-printing * {
    display: none;
  }
  .rpv-print-zone {
    display: block;
    height: 100%;
  }
  .rpv-print-page-thumbnail {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
    page-break-after: always;
    page-break-inside: avoid;
  }
  .rpv-print-page-thumbnail img {
    display: block;
    max-height: 100%;
    max-width: 100%;
  }
}
.rpv-properties-loader {
  text-align: center;
}
.rpv-properties-modal {
  padding: 8px 0;
}
.rpv-properties-modal-group {
  padding: 0 8px;
}
.rpv-properties-modal-footer {
  display: flex;
  justify-content: center;
  margin-top: 8px;
}
.rpv-property-item {
  margin: 8px 0;
}
.rpv-property-item-label {
  display: inline-block;
  padding-right: 8px;
  width: 30%;
}
.rpv-property-item-value {
  display: inline-block;
}
.rpv-scroll-mode-vertical {
  display: flex;
  flex-direction: column;
}
.rpv-scroll-mode-horizontal,
.rpv-scroll-mode-wrapped {
  display: flex;
  flex-direction: row;
}
.rpv-scroll-mode-wrapped {
  flex-wrap: wrap;
  justify-content: center;
}
.rpv-search-text-highlight {
  background-color: rgba(255, 255, 0, 0.4);
  border-radius: 4px;
  position: absolute;
}
.rpv-search-popover {
  padding: 0 8px;
}
.rpv-search-popover-input-counter {
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  margin-bottom: 8px;
  position: relative;
  width: 180px;
}
.rpv-search-popover-input {
  border: none;
  padding: 4px;
  width: 100%;
}
.rpv-search-popover-counter {
  align-items: center;
  bottom: 0;
  display: flex;
  padding-right: 4px;
  position: absolute;
  right: 0;
  top: 0;
}
.rpv-search-popover-label {
  align-items: center;
  display: flex;
  margin-bottom: 8px;
}
.rpv-search-popover-label-checkbox {
  margin-right: 4px;
}
.rpv-search-popover-footer {
  align-items: center;
  display: flex;
}
.rpv-search-popover-footer-item {
  padding: 0 4px;
}
.rpv-search-popover-footer-button {
  margin-left: auto;
}
.rpv-selection-mode-grab {
  cursor: grab;
}
.rpv-selection-mode-grab :not(input),
.rpv-selection-mode-grab :not(select),
.rpv-selection-mode-grab :not(textarea) {
  cursor: grab !important;
}
.rpv-selection-mode-grabbing {
  cursor: grabbing;
}
.rpv-selection-mode-grabbing :not(input),
.rpv-selection-mode-grabbing :not(select),
.rpv-selection-mode-grabbing :not(textarea) {
  cursor: grabbing !important;
}
.rpv-zoom-popover-target {
  align-items: center;
  display: flex;
  padding: 8px;
}
.rpv-zoom-popover-target-scale {
  margin-right: 4px;
}
.rpv-zoom-popover-target-arrow {
  border-color: rgba(0, 0, 0, 0.6) transparent transparent;
  border-style: solid;
  border-width: 8px 4px 0;
  height: 0;
  width: 0;
}
.rpv-toolbar {
  width: 100%;
}
.rpv-toolbar,
.rpv-toolbar-center,
.rpv-toolbar-left {
  align-items: center;
  display: flex;
}
.rpv-toolbar-center {
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: center;
}
.rpv-toolbar-right {
  align-items: center;
  display: flex;
  margin-left: auto;
}
.rpv-toolbar-item {
  padding: 0 2px;
}

.rpv-default-layout-toolbar {
  background-color: #eee;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 4px;
}

.rpv-default-layout__body {
  overflow-x: hidden !important;
}

.rpv-default-layout__sidebar {
  display: none !important;
}
