.CRUDTableWrapper > div {
  overflow: auto;
}

.CRUDTableWrapper > div:last-child {
  border-bottom: 1px solid;
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.CRUDTableWrapper > div > div > div > div:first-child {
  position: sticky;
  top: 0;
}
